import {
  CSSProperties,
  RefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  autoPlacement,
  autoUpdate,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { useTranslation } from "next-i18next";

import Portal from "@sellernote/_shared/src/components/Portal";
import {
  UserPort,
  WareHouse,
} from "@sellernote/_shared/src/types/common/common";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import InputSearch, {
  InputSearchProps,
} from "@sellernote/_sds-v2/src/components/form/InputSearch";
import Loading from "@sellernote/_sds-v2/src/components/Loading";
import ExclamationTriangleIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExclamationTriangleIcon";
import { TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import {
  InputSearchWithCategoryPortOption,
  InputSearchWithPortOption,
} from "./types";

import PortOptionItem from "./components/PortOptionItem";
import PortOptionWithCategoryItem from "./components/PortOptionWithCategoryItem";

import { SEARCH_RESULT_STATE } from "./constants";
import Styled from "./index.styles";

type PortInfoType = UserPort | WareHouse | undefined;
interface InputProps
  extends Pick<
    InputSearchProps,
    | "onBlur"
    | "onFocus"
    | "onReset"
    | "onKeyDown"
    | "placeholder"
    | "labelInfo"
    | "leftIconInfo"
    | "errorMessage"
  > {
  className?: string;
  width?: CSSProperties["width"];
  onSelect: (portInfo: PortInfoType) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  focusHandlerRef?: RefObject<{ focusInput: () => void }>;
}

type FloatingProps = {
  width: string;
  style: CSSProperties;
  ref: (node: HTMLElement | null) => void;
} & Record<string, unknown>;

interface SearchSourceListType<T> {
  searchSourceList: (
    | InputSearchWithPortOption<T>
    | InputSearchWithCategoryPortOption<T>
  )[];
  focusSearchSourceList?: (
    | InputSearchWithPortOption<T>
    | InputSearchWithCategoryPortOption<T>
  )[];
}

interface InputSearchWithPortOptionsProps {
  searchTerm: string;
}

export type {
  InputProps,
  FloatingProps,
  SearchSourceListType,
  InputSearchWithPortOptionsProps,
  PortInfoType,
};

type Props<T> = InputSearchWithPortOptionsProps &
  InputProps &
  SearchSourceListType<T>;

// 카테고리 옵션 타입가드 함수
const isCategoryOption = <T,>(
  option: InputSearchWithCategoryPortOption<T> | InputSearchWithPortOption<T>
): option is InputSearchWithCategoryPortOption<T> => "category" in option;

export default function InputSearchWithPortOptions<T>({
  searchTerm,
  searchSourceList,
  focusSearchSourceList,

  width,
  className,
  placeholder,
  leftIconInfo,
  labelInfo,
  errorMessage,

  onChange,
  onReset,
  onBlur,
  onFocus,
  onKeyDown,
  onSelect,
  focusHandlerRef,

  isLoading,
}: Props<T>) {
  const { t } = useTranslation();

  const { isMobile } = useCheckIsMobile();

  const inputRef = useRef<HTMLInputElement>(null);

  const [opensOptionList, setOpensOptionList] = useState(false);

  const [isFocus, setIsFocus] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: "fixed",
    open: opensOptionList,
    onOpenChange: (isOpen) => {
      if (!isOpen) setIsFocus(false);

      setOpensOptionList(isOpen);
    },
    middleware: [
      autoPlacement({
        allowedPlacements: ["bottom-start"],
        autoAlignment: false,
      }),
    ],
  });

  const dismiss = useDismiss(context, {
    // 오버플로우 영역을 스크롤하면 옵션리스트가 닫히는 조건 활성화 (모바일 IOS 대응)
    ancestorScroll: isMobile,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  /** 인풋요소 기준으로 fixed 스타일 계산 */
  const searchResultOptionWidth = (() => {
    const selectOptionElementRect =
      refs.reference.current?.getBoundingClientRect();

    return `${selectOptionElementRect?.width || 0}px`;
  })();

  const handleFocus = () => {
    onFocus?.();

    setIsFocus(true);

    setOpensOptionList(true);
  };

  const handleSelect = useCallback(
    (portInfo: UserPort | WareHouse | undefined) => {
      onSelect(portInfo);

      setIsFocus(false);

      setOpensOptionList(false);
    },
    [onSelect]
  );

  const highlightMatch = useCallback((label: string, searchTerm: string) => {
    if (!searchTerm) return label;

    const regex = new RegExp(`(${searchTerm})`, "gi");

    const parts = label.split(regex);

    return parts.map((part, i) =>
      regex.test(part) ? (
        <Styled.highlightMatch key={i}>{part}</Styled.highlightMatch>
      ) : (
        part
      )
    );
  }, []);

  const hasFocusSearchSourceList = (() => {
    return focusSearchSourceList?.some((option) => {
      if ("category" in option) {
        return option.optionList.length;
      }

      return option;
    });
  })();

  const isEmptySearchSourceList = (() => {
    return searchSourceList.every((option) => {
      if ("category" in option) {
        return !option.optionList.length;
      }

      return !option;
    });
  })();

  const showsFocusPanel = (() => {
    if (!hasFocusSearchSourceList) return false;

    return isFocus && !searchTerm && hasFocusSearchSourceList;
  })();

  const commonFloatingProps = useMemo(() => {
    return {
      width: searchResultOptionWidth,
      ref: refs.setFloating,
      ...getFloatingProps(),
      style: floatingStyles,
    };
  }, [searchResultOptionWidth, refs, floatingStyles, getFloatingProps]);

  const optionItemProps = useMemo(
    () => ({
      searchTerm,
      onSelect: handleSelect,
      highlightMatch,
    }),
    [searchTerm, handleSelect, highlightMatch]
  );

  const searchResultState = useMemo(() => {
    if (isLoading) return SEARCH_RESULT_STATE.LOADING;
    if (!searchTerm || !opensOptionList) return SEARCH_RESULT_STATE.CLOSED;
    if (isEmptySearchSourceList) return SEARCH_RESULT_STATE.EMPTY;
    return SEARCH_RESULT_STATE.HAS_RESULTS;
  }, [isLoading, searchTerm, opensOptionList, isEmptySearchSourceList]);

  useImperativeHandle(focusHandlerRef, () => ({
    focusInput: () => {
      inputRef.current?.focus();
    },
  }));

  return (
    <Styled.container
      ref={refs.setReference}
      {...getReferenceProps()}
      width={width}
      className={`${className ? className : ""} input-search-with-port-options`}
    >
      <InputSearch
        inputRef={inputRef}
        labelInfo={labelInfo}
        leftIconInfo={leftIconInfo}
        onInputValueChange={onChange}
        onReset={onReset}
        onBlur={onBlur}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        inputValue={searchTerm}
        placeholder={
          placeholder ||
          t(
            "common-renewal:InputSearchWithPortOptions.항구/공항을_입력해주세요."
          )
        }
        errorMessage={errorMessage}
      />

      <Portal selector="#app-portal">
        {showsFocusPanel && (
          <Styled.optionList {...commonFloatingProps}>
            {focusSearchSourceList?.map((option) =>
              isCategoryOption(option) ? (
                <PortOptionWithCategoryItem
                  key={option.category.label}
                  {...option}
                  {...optionItemProps}
                />
              ) : (
                <PortOptionItem
                  key={option.label}
                  {...option}
                  {...optionItemProps}
                />
              )
            )}
          </Styled.optionList>
        )}

        {isFocus && searchResultState === SEARCH_RESULT_STATE.LOADING && (
          <Styled.loading {...commonFloatingProps}>
            <Loading
              direction="row"
              message={t(
                "common-renewal:InputSearchWithPortOptions.검색결과_로드중..."
              )}
            />
          </Styled.loading>
        )}

        {isFocus && searchResultState === SEARCH_RESULT_STATE.EMPTY && (
          <Styled.emptySearchResult {...commonFloatingProps}>
            <ExclamationTriangleIcon
              width={20}
              height={20}
              color={TEXT_COLOR.black_disabled}
            />

            <p>
              {t(
                "common-renewal:InputSearchWithPortOptions.검색결과가_없습니다."
              )}
            </p>
          </Styled.emptySearchResult>
        )}

        {isFocus && searchResultState === SEARCH_RESULT_STATE.HAS_RESULTS && (
          <Styled.optionList {...commonFloatingProps}>
            {searchSourceList.map((option) =>
              isCategoryOption(option) ? (
                <PortOptionWithCategoryItem
                  key={option.category.label}
                  {...option}
                  {...optionItemProps}
                />
              ) : (
                <PortOptionItem
                  key={option.label}
                  {...option}
                  {...optionItemProps}
                />
              )
            )}
          </Styled.optionList>
        )}
      </Portal>
    </Styled.container>
  );
}
